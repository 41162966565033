import React from 'react';
import './table.css';
import DownloadButton from '../downloadButton/DownloadButton';

type DataRow = {
  [key: string]: string;
};

type TableProps = {
  data: string;
  id: string;
};

const Table: React.FC<TableProps> = ({ data, id }) => {
  const parsedData: DataRow[] = JSON.parse(data);

  const headers: string[] =
    parsedData.length > 0 ? Object.keys(parsedData[0]) : [];

  return (
    <div className="tableContainer">
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header.replace(/_/g, ' ')}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {parsedData.map((item, index) => (
            <tr key={index}>
              {headers.map((header) => (
                <td key={header}>{item[header]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="footer">
        <span>CSV File</span>
        <DownloadButton id={id} />
      </div>
    </div>
  );
};

export default Table;
