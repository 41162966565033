import { useEffect, useRef } from 'react';
import './chatwindow.css';
import { ChatSpeech } from '@data-products-and-ai/react-components';
import accountLogo from '../../assets/icons/account_circle_icon.svg';
import Suggestions from './Suggestions';
import EditableMessageComponent from './EditableMessageComponent';
import { MessageFormat } from '@/types/messageFormat';
import Table from '../ui/table/Table';
import Feedback from './feedback/Feedback';
import { Session } from '@/types/sessionsList';

type ChatWindowProps = {
  token: string | undefined;
  messages: MessageFormat[];
  sendClicked: boolean;
  handleSendButton: (userQuestion: string) => void;
  handleRunSql?: (newSql: string, sqlId: string) => void;
  sessionId: Session;
};

/**
 * ChatWindow component renders a chat interface displaying user and bot messages.
 *
 * @component
 * @param {ChatWindowProps} props - The properties passed to the component.
 * @param {MessageFormat[]} props.messages - The array of message objects to be displayed in the chat.
 * @param {boolean} props.sendClicked - Flag indicating if the send button was clicked.
 * @param {function} props.handleSendButton - Function to handle sending a user's question.
 * @param {function} [props.handleRunSql] - Optional function to handle running SQL commands.
 * @param {Session} [props.sessionId] - The session ID from the /star_session api or a chatbox.
 * @returns {JSX.Element} The rendered ChatWindow component.
 */
function ChatWindow({
  token,
  messages,
  sendClicked,
  handleSendButton,
  handleRunSql,
  sessionId,
}: ChatWindowProps) {
  const chatEndRef = useRef<HTMLDivElement>(null);

  /**
   * Scrolls the chat window to the bottom.
   */
  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="chat-window">
      <h1>How can I help you today?</h1>
      {messages.length > 0 &&
        messages.map((messageObject: MessageFormat, i: number) => {
          return messageObject.sender === 'user' ? (
            <ChatSpeech
              key={`${i}chatBubbleUser`}
              showFeedback={false}
              avatar={<img src={accountLogo} alt="logo" width={30} />}
              borderRadius={10}
              chatBubble
              chatBubbleMargin={100}
              chatBubblePadding={10}
              placement="left"
              showTime={false}
              textSize="medium"
              type="question"
            >
              <EditableMessageComponent
                message={messageObject.message}
                handleSendButton={handleSendButton}
              />
            </ChatSpeech>
          ) : (
            <div key={i}>
              <ChatSpeech
                key={`${i}chatBubbleResponse`}
                chatBubble
                chatBubbleMargin={100}
                chatBubblePadding={10}
                showFeedback={false}
                placement="right"
                showTime={false}
                textSize="medium"
                type="answer"
              >
                <>
                  {messageObject.type === 'df' ? (
                    <Table data={messageObject.message} id={messageObject.id} />
                  ) : messageObject.type === 'sql' ? (
                    <EditableMessageComponent
                      message={messageObject.message}
                      handleRunSql={handleRunSql}
                      sqlId={messageObject.id}
                    />
                  ) : messageObject.type === 'error' ? (
                    messageObject.message
                  ) : (
                    messageObject.message
                  )}
                  {messageObject.suggestions &&
                  messageObject.suggestions.length > 0 ? (
                    <Suggestions
                      suggestionsMessages={messageObject.suggestions}
                      handleSendButton={handleSendButton}
                      disable={sendClicked}
                    />
                  ) : null}
                </>
              </ChatSpeech>
              <Feedback
                key={`${i}feedback`}
                data={messageObject}
                token={token || ''}
                sessionId={sessionId || { session_id: '', chat_name: '' }}
              />
            </div>
          );
        })}

      {sendClicked && (
        <ChatSpeech
          chatBubble
          chatBubbleMargin={100}
          chatBubblePadding={10}
          placement="right"
          textSize="medium"
          type="question"
          showTime={false}
          showFeedback={false}
        >
          Thinking...
        </ChatSpeech>
      )}

      <div ref={chatEndRef} />
    </div>
  );
}

export default ChatWindow;
