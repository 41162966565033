import React from 'react';
import './downloadButton.css';
import { getData } from '@/service/apicalls';
import { useGetToken } from '@/hooks/useGetToken';
interface DownloadButtonProps {
  id: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ id }) => {
  const { token } = useGetToken();
  // const token = 'token';

  const handleDownload = async () => {
    if (token) {
      try {
        const response = await getData<string>(
          `${
            import.meta.env.VITE_APP_BASE_URL || 'http://127.0.0.1:3000'
          }/api/v0/download_csv`,
          token,
          { id },
        ); // Assuming the response is a string

        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'your_filename_here.csv'; // Customize your filename here
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    }
  };

  return <button onClick={handleDownload}>Download CSV</button>;
};

export default DownloadButton;
