import './loginPage.css';
import BackgroundImage from '../../assets/img/background1.jpg';
import logoImage from '../../assets/img/logo.jpg';
import microsoftLogo from '../../assets/icons/microsoft.svg';
import { Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { handleLogin } from '@/auth/signIn';

const LoginPage = () => {
  const { instance } = useMsal();

  return (
    <div className="loginPage">
      <div className="backgroundContainer">
        <img src={BackgroundImage} alt="background" />
      </div>
      <div className="formContainer">
        <img src={logoImage} alt="logo" />
        <form>
          <label htmlFor="loginButton">Login</label>
          <button onClick={(e) => handleLogin(e, instance)}>
            <img src={microsoftLogo} alt="logo" /> Sign in with Microsoft
          </button>
        </form>
        <Link to="/chat">Contact us</Link>
      </div>
    </div>
  );
};

export default LoginPage;
