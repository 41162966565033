import { MessageFormat } from '@/types/messageFormat';
import {
  ButtonIcon,
  Select,
  SelectOption,
} from '@data-products-and-ai/react-components';
import './feedback.css';
import { useState } from 'react';
import { postData } from '@/service/apicalls';
import { Session } from '@/types/sessionsList';
import { FeedbackResponse } from '@/types/apiResponse';

type FeedbackProps = {
  data: MessageFormat;
  token: string | undefined;
  sessionId: Session;
};
const Reasons = [
  {
    value: 'Incomplete',
    label: 'Incomplete response',
    action: 'Needs more information',
  },
  { value: 'Outdated', label: 'Outdated response', action: 'Needs updating' },
  {
    value: 'Incorrect',
    label: 'Factually incorrect information',
    action: 'Needs correcting',
  },
  {
    value: 'NotRelevant',
    label: 'Information provided is not relevant',
    action: 'Model needs more training',
  },
];

/**
 * Feedback component allows users to provide feedback on a specific message.
 * Users can give a thumbs up or thumbs down and select a reason for negative feedback.
 *
 * @component
 * @param {FeedbackProps} props - The properties passed to the component.
 * @param {MessageFormat} props.data - The message data to provide feedback on.
 * @param {string | undefined} props.token - The authentication token.
 * @param {Session} props.sessionId - The session ID associated with the message.
 */
const Feedback = ({ data, token, sessionId }: FeedbackProps) => {
  const [showReasons, setShowReasons] = useState<boolean>(false);
  const [FeedbackSent, setFeedbackSent] = useState<boolean>(false);
  const [loadingFeedBack, setLoadingFeedback] = useState<boolean>(false);

  /**
   * Sends feedback to the server.
   *
   * @param {string} feedback - The feedback type (e.g., 'Like', 'Dislike').
   * @param {string} [reason] - The reason for the feedback (if applicable).
   * @returns {Promise<FeedbackResponse | void>} The server response.
   */
  const giveFeedback = async (feedback: string, reason?: string) => {
    if (token) {
      setLoadingFeedback(true);
      try {
        const res: FeedbackResponse = await postData(
          `${
            import.meta.env.VITE_APP_BASE_URL || 'http://127.0.0.1:3000'
          }/api/v0/collect_feedback`,
          token,
          {
            session_id: sessionId.session_id,
            message_id: data.id,
            feedback: feedback,
            reason: reason ? reason : '',
          },
        );
        if (res.message) {
          setLoadingFeedback(false);
          return res;
        }
      } catch (error) {
        console.log(error);
        setLoadingFeedback(false);
      }
    }
  };

  /**
   * Handles thumbs up feedback.
   */
  const handleThumbsUp = async () => {
    const res = await giveFeedback('Like');
    if (res) {
      setFeedbackSent(true);
    }
  };

  /**
   * Displays reason selection for thumbs down feedback.
   */
  const handleThumbsDown = () => {
    setShowReasons(true);
  };

  /**
   * Handles reason selection change.
   *
   * @param {React.ChangeEvent<HTMLSelectElement>} event - The change event object.
   */
  const handleSelectValue = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const res = await giveFeedback(
      'Dislike',
      event.target.options[event.target.selectedIndex].value,
    );
    if (res) {
      setFeedbackSent(true);
    }
    setShowReasons(false);
  };

  return (
    <>
      {showReasons ? (
        <div className="reasonContainer">
          <Select
            id="FeedbackReason"
            placeholder="Select a reason"
            size="small"
            onChange={(e) => handleSelectValue(e)}
            width={400}
          >
            {Reasons.map((elem, i) => (
              <SelectOption key={i} value={elem.value} label={elem.label} />
            ))}
          </Select>
        </div>
      ) : FeedbackSent ? (
        <p id="feedbackSent">Thanks for your feedback</p>
      ) : loadingFeedBack ? (
        <p>Loading...</p>
      ) : (
        <div className="feedBackContainer">
          <ButtonIcon
            onClick={handleThumbsUp}
            icon="IconThumbUp"
            size="small"
            hoverType="color"
          />
          <ButtonIcon
            onClick={handleThumbsDown}
            icon="IconThumbDown"
            size="small"
            hoverType="color"
          />
        </div>
      )}
    </>
  );
};

export default Feedback;
