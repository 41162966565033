import { getData } from '@/service/apicalls';
import './sessionsList.css';
import { Session } from '@/types/sessionsList';
import { MessageFormat } from '@/types/messageFormat';
import { ChatHistory } from '@/types/apiResponse';
import { useEffect, useState } from 'react';

type SessionsListProps = {
  data: Session[];
  loading: boolean;
  token?: string | null;
  setMessages: React.Dispatch<React.SetStateAction<MessageFormat[]>>;
  setPreviousChatLoading: React.Dispatch<React.SetStateAction<boolean>>;
  newChatId?: Session;
  setSessionId: React.Dispatch<React.SetStateAction<Session | undefined>>;
  setchatName: React.Dispatch<React.SetStateAction<string>>;
};

/**
 * SessionsList component
 * Displays a list of chat sessions and allows the user to select a session to view its chat history.
 *
 * @component
 * @param {SessionsListProps} props - The component props
 * @param {Session[]} props.data - The list of chat sessions
 * @param {boolean} props.loading - Indicates whether the sessions are loading
 * @param {string | null} [props.token] - The authentication token
 * @param {React.Dispatch<React.SetStateAction<MessageFormat[]>>} props.setMessages - Function to set chat messages
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setPreviousChatLoading - Function to set the loading state for previous chat
 * @param {Session} [props.newChatId] - The ID of the new chat session
 * @param {React.Dispatch<React.SetStateAction<Session | undefined>>} props.setSessionId - Function to set the current session ID
 * @param {React.Dispatch<React.SetStateAction<string>} props.setchatName - Function to set the current chatName that user selected and be ready to be sent on the chatPAGE apicalls
 */
const SessionsList = ({
  data,
  loading,
  token,
  setMessages,
  setPreviousChatLoading,
  newChatId,
  setSessionId,
  setchatName,
}: SessionsListProps) => {
  const [sessId, setSessId] = useState('');

  useEffect(() => {
    if (newChatId) {
      setSessId(newChatId.session_id);
    }
  }, [newChatId]);

  /**
   * Handles the selection of a chat session.
   *
   * @param {Session} id - The selected session ID
   */
  const handleSelectSession = async (id: Session) => {
    setSessId(id.session_id);
    setSessionId(id);
    setchatName(id.chat_name);
    if (token) {
      setPreviousChatLoading(true);
      try {
        const res = await getData<ChatHistory>(
          `${
            import.meta.env.VITE_APP_BASE_URL || 'http://127.0.0.1:3000'
          }/api/v0/get_last_n_histories`,
          token,
          {
            session_id: id.session_id,
          },
        );
        if (res) {
          setMessages(res.history);
          setPreviousChatLoading(false);
        }
      } catch (error) {
        console.log(error);
        setPreviousChatLoading(false);
      }
    }
  };

  return (
    <div className="sessionsContainer">
      {loading && <span>Loading...</span>}
      {data &&
        data.map((elem, i) => (
          <button
            className="sessionBoxContainer"
            key={i}
            onClick={() => handleSelectSession(elem)}
            style={{
              backgroundColor: sessId === elem.session_id ? '#faf3ff' : '',
            }}
          >
            <h4>{elem.chat_name ? elem.chat_name : 'Chat Box'}</h4>
          </button>
        ))}
    </div>
  );
};

export default SessionsList;
