
import './chatPage.css';
import ChatWindow from '@/components/ChatBotComponents/ChatWindow';
import SideBarComponent from '@/components/SideBar/SideBarComponent';
import { MessageFormat } from '@/types/messageFormat';

import {
  ButtonIcon,
  Header,
  Input,
} from '@data-products-and-ai/react-components';
import { useEffect, useState } from 'react';
import { getData } from '@/service/apicalls';
import {
  ChatMessageFormat,
  UserMessageFormat,
  errorMessageFormat,
  suggestionMessageFormat,
} from '@/utils/messageFormater';
import {
  ChatApiResponse,
  SqlApiResponse,
  SuggestionsResponse,
} from '@/types/apiResponse';
import { useGetToken } from '@/hooks/useGetToken';
import { Session } from '@/types/sessionsList';

/**
 * The main chat page component.
 * Handles user input, displays chat messages, and interacts with various APIs.
 *
 * @component
 */
const ChatPage = () => {
  const [question, setQuestion] = useState<string>('');
  const [messages, setMessages] = useState<MessageFormat[]>(LoadingMessage);
  const [sendClicked, setSendClicked] = useState(false);
  const [projectName, setProjectName] = useState<string>();
  const [LLMmodel, setLLMmodel] = useState<string>();
  const [chatName, setchatName] = useState<string>('');
  const [, setSqlId] = useState('');
  const [sessionId, setSessionId] = useState<Session>();
  const [newChatId, setNewChatId] = useState<Session>();
  const [previousChatLoading, setPreviousChatLoading] =
    useState<boolean>(false);
 
  const { token } = useGetToken();

  /**
   * Fetches suggestions based on the current project name.
   * if we have a projectname change we will passed it as a param
   * because setProjectName will not be changed on time
   */
  const getSuggestions = async (pn?: string) => {
    setMessages(LoadingMessage);
    if (token) {
      try {
        setSendClicked(true);
        const data = await getData<SuggestionsResponse>(
          `${import.meta.env.VITE_APP_BASE_URL}/api/v0/generate_questions`,
          token,
          { project: pn ? pn : projectName, llm_model: LLMmodel },
        );
        if (data.questions) {
          setSendClicked(false);
          setMessages([
            suggestionMessageFormat(data.questions, data.header, data.type),
          ]);
        }
        if (data.error) {
          setSendClicked(false);
          setMessages([errorMessageFormat(data.error, data.type)]);
        }
      } catch (error) {
        console.log(error);
        setSendClicked(false);
      }
    }
  };

  useEffect(() => {
    if (projectName && token && messages.length <= 1) {
      getSuggestions();
    }
  }, [projectName, token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (token) {
      handleNewChat();
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles changes to the question input field.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event object.
   */
  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion('');
    const inputValue = event.target.value;
    setQuestion(inputValue);
  };

  /**
   * It sends a call to /generate_sql if response okay,
   * it will call run_sql to fetch a preview dataframe that renders on <ChatWindow/>
   *
   * @param {string} userQuestion - The question entered by the user.
   */
  const handleSendButton = async (userQuestion: string) => {
    setSendClicked(true);
    setQuestion('');
    let chatN = '';
    if (messages.length === 1) {
      chatN = userQuestion.slice(0, 27);
      setNewChatId(sessionId);
      setchatName(userQuestion.slice(0, 27));
    }
    const userId = crypto.randomUUID();
    setMessages((previous_messages) => [
      ...previous_messages,
      UserMessageFormat(userQuestion, userId),
    ]);

    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 60000);

    let id = '';
    if (token && sessionId) {
      try {
        const data = await getData<ChatApiResponse>(
          `${
            import.meta.env.VITE_APP_BASE_URL || 'http://127.0.0.1:3000'
          }/api/v0/generate_sql`,
          token,
          {
            project: projectName,
            llm_model: LLMmodel,
            question: userQuestion,
            session_id: sessionId.session_id,
            chat_name: chatN ? chatN : chatName,
          },
          signal,
        );

        clearTimeout(timeoutId);

        if (data) {
          id = data.id;
          setSqlId(data.id);
          setSendClicked(false);
          setMessages((previous_messages) => [
            ...previous_messages,
            ChatMessageFormat(data.text, data.type, id),
          ]);
          if (data.type === 'sql') {
            setSendClicked(true);

            const sql = await getData<SqlApiResponse>(
              `${
                import.meta.env.VITE_APP_BASE_URL || 'http://127.0.0.1:3000'
              }/api/v0/run_sql`,
              token,
              {
                id,
                project: projectName,
                llm_model: LLMmodel,
                session_id: sessionId.session_id,
              },
            );

            if (sql?.df) {
              setSendClicked(false);
              setMessages((previous_messages) => [
                ...previous_messages,
                ChatMessageFormat(sql.df, sql.type, id),
              ]);
            } else if (sql?.message) {
              setSendClicked(false);
              setMessages((previous_messages) => [
                ...previous_messages,
                ChatMessageFormat(sql.message, sql.type, id),
              ]);
            } else if (sql?.error) {
              setSendClicked(false);
              setMessages((previous_messages) => [
                ...previous_messages,
                errorMessageFormat(sql.error, sql.type),
              ]);
            }
          }
        } else {
          setSendClicked(false);
        }
      } catch (error) {
        if (error instanceof Error && error.message === 'Request was aborted') {
          setSendClicked(false);
          setMessages((previous_messages) => [
            ...previous_messages,
            errorMessageFormat(
              'The request took too long and was aborted.',
              'error',
            ),
          ]);
        } else if (error) {
          setSendClicked(false);
          setMessages((previous_messages) => [
            ...previous_messages,
            errorMessageFormat('There was an error.', 'error'),
          ]);
        }
      }
    }
  };

  /**
   * Handles running SQL queries.
   *
   * @param {string} sqlMessage - The SQL message to run.
   * @param {string} sqlId - The SQL query identifier.
   */
  const handleRunSql = async (sqlMessage: string, sqlId: string) => {
    setSendClicked(true);
    setMessages((previous_messages) => [
      ...previous_messages,
      ChatMessageFormat(sqlMessage, 'sql', sqlId),
    ]);
    if (token) {
      const res = await getData<SqlApiResponse>(
        `${
          import.meta.env.VITE_APP_BASE_URL || 'http://127.0.0.1:3000'
        }/api/v0/run_sql`,
        token,
        {
          id: sqlId,
          sql: sqlMessage,
          project: projectName,
          llm_model: LLMmodel,
        },
      );
      if (res.df) {
        setSendClicked(false);
        setMessages((previous_messages) => [
          ...previous_messages,
          ChatMessageFormat(res.df, res.type, sqlId),
        ]);
      }
      if (res.error) {
        setSendClicked(false);
        setMessages((previous_messages) => [
          ...previous_messages,
          errorMessageFormat(res.error, res.type),
        ]);
      }
    }
  };

  /**
   * Handles the creation of a new chat session.
   * It will fetch a new session id and set a newChatId
   * this last state will trigger the creation of a new chat box on the side bar
   *
   * @param {string} projectName - Project name to be passed to getSuggestions()
   * when user change it on the dropdowns.
   */
  const handleNewChat = async (projectName?: string) => {
    if (token) {
      try {
        const res = await getData<Session>(
          `${import.meta.env.VITE_APP_BASE_URL}/api/v0/start_session`,
          token,
        );
        if (res) {
          setSessionId(res);
          if (messages.length > 1) {
            setchatName('New Chat');
            setNewChatId(res);
            setMessages(LoadingMessage);
            getSuggestions(projectName);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  /**
   * Handles the Enter key press event in the input field.
   *
   * @param {React.KeyboardEvent<HTMLInputElement>} event - The keyboard event object.
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !sendClicked) {
      handleSendButton(question);
    }
  };

  return (
    <div className="main-container">
      <Header
        size="small"
        title={'SNOCHAT'}
        hasSignOut={true}
      />
      <div className="main-content">
        <div className="left">
          <SideBarComponent
            setProjectName={setProjectName}
            setLLMmodel={setLLMmodel}
            handleNewChat={handleNewChat}
            newChatId={newChatId}
            setSessionId={setSessionId}
            isChatEmpty={messages.length <= 1}
            setMessages={setMessages}
            chatName={chatName}
            setPreviousChatLoading={setPreviousChatLoading}
            setchatName={setchatName}
          />
        </div>
        <div className="right">
          <div className="top">
            {previousChatLoading ? (
              <h3 id="loadingChat">Loading Chat...</h3>
            ) : (
              <ChatWindow
                token={token || ''}
                messages={messages}
                sendClicked={sendClicked}
                handleSendButton={handleSendButton}
                handleRunSql={handleRunSql}
                sessionId={sessionId || { session_id: '', chat_name: '' }}
              />
            )}
          </div>
          <div className="bottom">
            <Input
              id="input-prompt"
              placeholder="Please Enter your question"
              width="100%"
              size="large"
              defaultValue={question}
              onChange={handleQuestionChange}
              onKeyDown={handleKeyDown}
              disabled={sendClicked}
            />
            <ButtonIcon
              icon="IconSend"
              onClick={() => !sendClicked && handleSendButton(question)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatPage;

const LoadingMessage: MessageFormat[] = [
  {
    id: '1',
    sender: 'bot',
    message: 'Loading suggestions...',
    suggestions: [],
    type: 'string',
  },
];
