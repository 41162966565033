import ChatPage from '@/pages/chat/ChatPage';
import LoginPage from '@/pages/login/LoginPage';
import NotFoundPage from '@/pages/notFound/NotFoundPage';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { Routes, Route } from 'react-router-dom';

/**
 * Routers component handles the routing for the application.
 * It uses `react-router-dom` for defining routes and `@azure/msal-react`
 * for handling authentication templates.
 *
 * @component
 * @returns {JSX.Element} The rendered routing component.
 */

const Routers = (): JSX.Element => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <UnauthenticatedTemplate>
                <LoginPage />
              </UnauthenticatedTemplate>
              <AuthenticatedTemplate>
                <ChatPage />
              </AuthenticatedTemplate>
            </>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default Routers;
