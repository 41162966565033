import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { tokenRequest } from '@/auth/authConfig';

export const useGetToken = () => {
  const { instance, accounts } = useMsal();
  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const acquireToken = async () => {
      if (accounts.length > 0) {
        const account = accounts[0];
        try {
          const response = await instance.acquireTokenSilent({
            ...tokenRequest,
            account: account,
          });
          setToken(response.accessToken);
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            try {
              const response = await instance.acquireTokenPopup(tokenRequest);
              setToken(response.accessToken);
            } catch (popupError) {
              console.error('Token acquisition via popup failed:', popupError);
              setError(popupError as Error);
            }
          } else {
            console.error('Silent token acquisition failed:', error);
            setError(error as Error);
          }
        }
      } else {
        setError(new Error('No accounts found.'));
      }
    };

    acquireToken();
  }, [instance, accounts]);

  return { token, error };
};
