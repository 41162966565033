import { IconChat } from '@data-products-and-ai/react-components/lib/components/DataDisplay/Icons';
import { useEffect, useState } from 'react';
import './sidebarcomponent.css';
import { getData } from '@/service/apicalls';
import { DropdownResponse } from '@/types/apiResponse';
import { useGetToken } from '@/hooks/useGetToken';
import { Session } from '@/types/sessionsList';
import SessionsList from './sessionsList/SessionsList';
import { MessageFormat } from '@/types/messageFormat';
import { updateChatName } from '@/utils/updateChatName';
import { hasDefualtChatName } from '@/utils/hasDefualtChatName';

type SideBarProps = {
  setProjectName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setLLMmodel: React.Dispatch<React.SetStateAction<string | undefined>>;
  handleNewChat: (projectName?: string) => Promise<void>;
  newChatId?: Session;
  setSessionId: React.Dispatch<React.SetStateAction<Session | undefined>>;
  isChatEmpty: boolean;
  setMessages: React.Dispatch<React.SetStateAction<MessageFormat[]>>;
  chatName: string;
  setPreviousChatLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setchatName: React.Dispatch<React.SetStateAction<string>>;
};

/**
 * SideBarComponent is a sidebar interface that allows users to select projects, models, and create new chats.
 *
 * @component
 * @param {SideBarProps} props - The properties passed to the component.
 * @param {React.Dispatch<React.SetStateAction<string | undefined>>} props.setProjectName - Function to set the project name.
 * @param {React.Dispatch<React.SetStateAction<string | undefined>>} props.setLLMmodel - Function to set the LLM model name.
 * @param {() => void} props.handleNewChat - Function to handle creating a new chat.
 * @param {Session} [props.newChatId] - The session ID for a new chat.
 * @param {React.Dispatch<React.SetStateAction<Session | undefined>>} props.setSessionId - Function to set the session ID.
 * @param {boolean} props.isChatEmpty - Indicates whether the chat is empty.
 * @param {React.Dispatch<React.SetStateAction<MessageFormat[]>>} props.setMessages - Function to set messages.
 * @param {string} props.chatName - The name of the chat.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setPreviousChatLoading - Function to set the loading state for previous chats.
 * @param {React.Dispatch<React.SetStateAction<string>} props.setchatName - Function to set the current chatName
 * @returns {JSX.Element} The rendered sidebar component.
 */
function SideBarComponent({
  setProjectName,
  setLLMmodel,
  handleNewChat,
  newChatId,
  setSessionId,
  isChatEmpty,
  setMessages,
  chatName,
  setPreviousChatLoading,
  setchatName,
}: SideBarProps) {
  const [projects, setProjects] = useState<DropdownResponse[]>([]);
  const [models, setModels] = useState<DropdownResponse[]>([]);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [loadingSideBar, setLoadingSideBar] = useState(false);

  const { token } = useGetToken();

  /**
   * Fetches sidebar data from a given URL using the provided token.
   *
   * @param {string} URL - The URL to fetch data from.
   * @param {string} token - The authentication token.
   * @returns {Promise<DropdownResponse[]>} The fetched sidebar data.
   */
  const getSideBarData = async (URL: string, token: string) => {
    try {
      const data = await getData(URL, token);
      return await data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (newChatId && hasDefualtChatName(sessions)) {
      setSessions(updateChatName(sessions, chatName));
    }
  }, [chatName, newChatId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * If there is a newChatId will create new chatbox on the side bar
   *
   */
  useEffect(() => {
    if (newChatId) {
      const newChatBox = {
        chat_name: chatName,
        session_id: newChatId.session_id,
      };
      sessions && sessions.length >= 1
        ? setSessions([newChatBox, ...sessions])
        : setSessions([newChatBox]);
    }
  }, [newChatId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (projects.length === 0 && token) {
      setLoadingSideBar(true);

      const projectsPromise = getSideBarData(
        `${
          import.meta.env.VITE_APP_BASE_URL || 'http://127.0.0.1:3000'
        }/api/v0/secrets/snowflake/project`,
        token,
      );

      const modelsPromise = getSideBarData(
        `${
          import.meta.env.VITE_APP_BASE_URL || 'http://127.0.0.1:3000'
        }/api/v0/secrets/llm/openai`,
        token,
      );

      const sessionsPromise = getSideBarData(
        `${
          import.meta.env.VITE_APP_BASE_URL || 'http://127.0.0.1:3000'
        }/api/v0/list_conversations`,
        token,
      );

      Promise.allSettled([projectsPromise, modelsPromise, sessionsPromise])
        .then((results) => {
          const [projectsResult, modelsResult, sessionsResult] = results;

          if (projectsResult.status === 'fulfilled' && projectsResult.value) {
            setProjects(projectsResult.value as DropdownResponse[]);
          }
          if (modelsResult.status === 'fulfilled' && modelsResult.value) {
            setModels(modelsResult.value as DropdownResponse[]);
          }
          if (sessionsResult.status === 'fulfilled' && sessionsResult.value) {
            setSessions(
              (sessionsResult.value as { conversations: Session[] })
                .conversations,
            );
          }
          setLoadingSideBar(false);
        })
        .catch((error) => {
          setLoadingSideBar(false);
          console.error(error);
        });
    }
  }, [projects.length, token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (projects.length > 0 && models.length > 0 && token) {
      setProjectName(projects[0].name);
      setLLMmodel(models[0].name);
    }
  }, [projects, token]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles project selection changes.
   *
   * @param {React.ChangeEvent<HTMLSelectElement>} event - The change event object.
   */
  const handleProject = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setProjectName(event.target.value);
    handleNewChat(event.target.value);
  };

  /**
   * Handles model selection changes.
   *
   * @param {React.ChangeEvent<HTMLSelectElement>} event - The change event object.
   */
  const handleModel = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLLMmodel(event.target.value);
  };

  return (
    <div className="sidebar">
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <select disabled={loadingSideBar} onChange={(e) => handleProject(e)}>
          {loadingSideBar && <option>Loading...</option>}
          {projects instanceof Array &&
            projects.length > 0 &&
            projects.map((elem, i) => <option key={i}>{elem.name}</option>)}
        </select>
        <select disabled={loadingSideBar} onChange={(e) => handleModel(e)}>
          {loadingSideBar && <option>Loading...</option>}
          {models instanceof Array &&
            models.length > 0 &&
            models.map((elem, i) => <option key={i}>{elem.name}</option>)}
        </select>
      </div>
      <button
        className="new-chat"
        disabled={isChatEmpty}
        onClick={() => handleNewChat}
      >
        <IconChat fill="#8a00e5" />
        <span>New Chat</span>
      </button>
      <hr />
      <SessionsList
        data={sessions}
        loading={loadingSideBar}
        token={token}
        setMessages={setMessages}
        setPreviousChatLoading={setPreviousChatLoading}
        newChatId={newChatId}
        setSessionId={setSessionId}
        setchatName={setchatName}
      />
    </div>
  );
}

export default SideBarComponent;
