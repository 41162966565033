import './suggestions.css';

type SuggestionsProps = {
  suggestionsMessages: string[];
  handleSendButton: (userQuestion: string) => void;
  disable: boolean;
};

/**
 * Suggestions component displays a list of suggestion messages as buttons.
 * When a suggestion button is clicked, it triggers the `handleSendButton` function
 * unless the `disable` flag is true.
 *
 * @component
 * @param {SuggestionsProps} props - The props for the component.
 * @param {string[]} props.suggestionsMessages - An array of suggestion messages to display.
 * @param {(userQuestion: string) => void} props.handleSendButton - Function to call when a suggestion is clicked.
 * @param {boolean} props.disable - Flag indicating whether the buttons should be disabled.
 * @returns {JSX.Element} The rendered component.
 */
function Suggestions({
  suggestionsMessages,
  handleSendButton,
  disable,
}: SuggestionsProps) {
  /**
   * Handles the click event on a suggestion button.
   * Calls the `handleSendButton` function if `disable` is false.
   *
   * @param {string} suggestion - The suggestion text that was clicked.
   */
  const handleSuggestionClick = (suggestion: string) => {
    if (!disable) {
      handleSendButton(suggestion);
    }
  };

  return (
    <div className="suggestions">
      {suggestionsMessages.map((suggestion: string, i: number) => (
        <button
          className="suggestion"
          key={i}
          onClick={() => handleSuggestionClick(suggestion)}
        >
          {suggestion}
        </button>
      ))}
    </div>
  );
}

export default Suggestions;
