export const UserMessageFormat = (userQuestion: string, id: string) => {
  return {
    id,
    sender: 'user',
    message: userQuestion,
    suggestions: [],
    type: 'string',
  };
};

export const ChatMessageFormat = (answer: string, type: string, id: string) => {
  return {
    id,
    sender: 'bot',
    message: answer,
    suggestions: [],
    type,
  };
};

export const errorMessageFormat = (error: string, type: string) => {
  return {
    id: '400',
    sender: 'bot',
    message: error,
    suggestions: [],
    type,
  };
};

export const suggestionMessageFormat = (
  suggestions: string[],
  message: string,
  type: string,
) => {
  return {
    id: '400',
    sender: 'bot',
    message,
    suggestions,
    type,
  };
};
