import { Footer } from '@data-products-and-ai/react-components';
import './App.css';
import Routers from './routes/routes';

function App() {
  return (
    <>
      <Routers />
      <Footer />
    </>
  );
}

export default App;
