import './notFoundPage.css';
import { useIsAuthenticated } from '@azure/msal-react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <div className="notFoundContainer">
      <h2> Nothing to see here!</h2>
      {isAuthenticated ? (
        <Link to="/chat">Return Home</Link>
      ) : (
        <Link to="/">Return Home to Sign In</Link>
      )}
    </div>
  );
};

export default NotFoundPage;
