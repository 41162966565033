import axios, { AxiosResponse } from 'axios';

type ResponseData<T> = T;

/**
 * Fetches data from a given API URL with optional query parameters.
 *
 * @template T - The type of the response data.
 * @template P - The type of the optional query parameters (default is object).
 * @param {string} APIURL - The URL of the API endpoint.
 * @param {string} token - The access token to authorize the request.
 * @param {P} [params] - Optional query parameters.
 * @returns {Promise<ResponseData<T>>} - A promise that resolves to the response data.
 * @throws Will throw an error if the request fails.
 */
export async function getData<T, P = object>(
  APIURL: string,
  token: string,
  params?: P,
  signal?: AbortSignal,
): Promise<ResponseData<T>> {
  try {
    const res: AxiosResponse<ResponseData<T>> = await axios.get(APIURL, {
      params: params || {},
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    throw error; // Rethrow the error so it can be handled by the caller if needed
  }
}

/**
 * Posts data to a given API URL.
 *
 * @template T - The type of the response data.
 * @template B - The type of the request body.
 * @param {string} APIURL - The URL of the API endpoint.
 * @param {B} body - The request body.
 * @returns {Promise<ResponseData<T>>} - A promise that resolves to the response data.
 * @throws Will throw an error if the request fails.
 */
export async function postData<T, B>(
  APIURL: string,
  token: string,
  body: B,
): Promise<ResponseData<T>> {
  try {
    const res: AxiosResponse<ResponseData<T>> = await axios.post(APIURL, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// const headers = new Headers();
//     const bearer = `Bearer ${accessToken}`;

//     headers.append("Authorization", bearer);

//     const options = {
//         method: "GET",
//         headers: headers
//     };

//     return fetch(graphConfig.graphMeEndpoint, options)
//         .then(response => response.json())
//         .catch(error => console.log(error));
// }
