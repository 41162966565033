import { Button, Icon, Input } from '@data-products-and-ai/react-components';
import { useState } from 'react';
import './editableMessageComponent.css';
type UserMessageComponentProps = {
  message: string;
  handleSendButton?: (userQuestion: string) => void;
  handleRunSql?: (newSql: string, sqlId: string) => void;
  sqlId?: string;
};

/**
 * EditableMessageComponent allows users to edit a message.
 * It displays the message and an edit button. When the edit button is clicked,
 * the message becomes editable. Users can submit the edited message or cancel the editing.
 *
 * @param {UserMessageComponentProps} props - The props for the component.
 * @param {string} props.message - The initial message to display and edit.
 * @param {(userQuestion: string) => void} [props.handleSendButton] - Optional function to call when the message is submitted.
 * @param {(newSql: string) => void} [props.handleRunSql] - Optional function to call when the message is submitted and contains SQL.
 * @returns {JSX.Element} The rendered component.
 */

function EditableMessageComponent({
  message,
  handleSendButton,
  handleRunSql,
  sqlId,
}: UserMessageComponentProps) {
  const [editable, setIsEditable] = useState(false);
  const [editedMessage, setEditedMessage] = useState(message);

  /**
   * Handles the edit button click event, setting the component to editable mode.
   */
  const handleEdit = () => {
    setIsEditable(true);
  };

  /**
   * Handles changes to the input field, updating the edited message state.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedMessage(e.target.value);
  };

  /**
   * Handles the submit button click event, calling the appropriate handler and disabling editable mode.
   */
  const handleSubmit = () => {
    setIsEditable(false);
    if (handleSendButton) {
      handleSendButton(editedMessage);
    }
    if (handleRunSql && sqlId) {
      handleRunSql(editedMessage, sqlId);
    }
  };

  /**
   * Handles the cancel button click event, reverting to the original message and disabling editable mode.
   */
  const handleCancel = () => {
    setIsEditable(false);
  };

  return (
    <div className="user-message-container">
      {editable === false && (
        <p style={handleRunSql && { fontFamily: 'Consolas' }}>{message}</p>
      )}
      {editable === false && (
        <button className="edit-icon" onClick={handleEdit}>
          <Icon iconSize={2} hoverColor="green" icon="IconEdit" />
        </button>
      )}
      {editable && (
        <div className="edit-box">
          <Input
            id="edit"
            defaultValue={message}
            size="large"
            autofocus={editable}
            onChange={(e) => handleChange(e)}
          />
          <div className="submit-cancel">
            <Button onClick={handleSubmit}>Submit</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditableMessageComponent;
