import { IPublicClientApplication } from '@azure/msal-browser';
import { loginRequest } from './authConfig';

export const handleLogin = (
  e: React.FormEvent,
  instance: IPublicClientApplication,
) => {
  e.preventDefault();
  instance.loginRedirect(loginRequest).catch((e) => {
    console.log(e);
  });
};
