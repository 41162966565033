import { Session } from '@/types/sessionsList';

export const updateChatName = (chats: Session[], newName: string) => {
  return chats.map((chat) => {
    if (chat.chat_name === 'New Chat') {
      return { ...chat, chat_name: newName };
    }
    return chat;
  });
};
